<script>
import FormErrors from '@/util/form-errors'
import CMExamConfigValidator from '../../classes/CMExamConfigValidator'

export default {
  name: 'CreateExamValidation',

  render() {
    return this.$scopedSlots.default({
      validationBeforeSend: this.validationBeforeSend,
    })
  },

  data: () => ({
    formErrors: new FormErrors()
  }),

  methods: {
    validationBeforeSend(payload) {
      new CMExamConfigValidator(payload.data, this.formErrors).validate()
      this.$emit('validated', {...payload, validator: this.formErrors})
    },
  }
}
</script>
